<template>
  <div id="login">
    <div class="login_form">
      <div class="login_form_in">
        <h2 class="title">登录</h2>
        <a-form-model :model="form" ref="form" :rules="rules">
          <a-form-model-item prop="mobile">
            <a-input v-model="form.mobile" placeholder="请输入手机号">
              <a-icon slot="prefix" type="mobile" style="color:rgba(0,0,0,.25)" />
            </a-input>

          </a-form-model-item>
          <a-form-model-item prop="pass">
            <a-input type="password" v-model="form.pass" placeholder="请输入密码" >
              <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button @click="handleSubmit">登录</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>
<script>
// import Cookies from 'js-cookie'
import { mapMutations } from 'vuex'
import { _session } from '@/utils'
export default {
  name: 'login',
  data () {
    return {
      form: {
        mobile: '',
        pass: ''
      },
      userInfo: null
    }
  },
  methods: {
    ...mapMutations(['getUserList']),
    handleSubmit () {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return false
        }
        const res = await this.$http.post('/login/vb-login', this.form)
        if (parseInt(res.code) !== 1) {
          console.log('aa')
          return this.$message.info(res.msg)
        }
        this.$message.success(res.msg)
        _session.set('token', res.data.token)
        const userList = {
          mobile: res.data.mobile,
          name: res.data.name,
          power: res.data.power
        }
        this.getUserList(userList)
        this.$router.push('/home')
      })
    }
  },
  computed: {
    rules () {
      // 验证手机号的规则
      const checkMobile = (ruls, value, cb) => {
      // 验证手机的正则表达式
        const regMobile = /^1[^012][0-9]{9}$/
        if (regMobile.test(value)) {
          return cb()
        } else {
          cb(new Error('手机号格式错误'))
        }
      }
      return {
        mobile: [
          { required: true, message: '请输入手机号!', trigger: 'blur' },
          { validator: checkMobile }
        ],
        pass: [{ required: true, message: '请输入密码!', trigger: 'blur' }]
      }
    }
  }
}
</script>
<style lang="less" scoped>
#login {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: url('../assets/images/login/bg.png') no-repeat center center/100%
    auto;justify-content: center;
    align-items: center;display:flex;
  .login_form {
    width: 1000px;
    height: 540px;
    background: url('../assets/images/login/loginBg.png') no-repeat;
    margin: 0 auto;
    .login_form_in {
      width: 500px;
        float: right;
        .title {
          text-align: center;
          margin-top: 152px;
          font-size: 20px;
        }

      .ant-form {
          width: 400px;
          margin: 0 auto;
      }
      .ant-btn{border: none; height: 46px;font-size: 16px; color: #fff;background-image:var(--color-background-image); background:var(--color-background); width: 100%;}
    }
  }
}
::v-deep .ant-input{border: 1px solid var(--color-tint);
    height: 40px;}
</style>
